<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="11" md="9" lg="8" >
          <span v-html="$t('privacy.policy')"></span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>

<style scoped>

</style>
